export function IconSmsIcon(props){
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="34.514" height="33.694" viewBox="0 0 34.514 33.694">
            <g id="Group_1008" data-name="Group 1008" transform="translate(-221.474 -415.793)">
                <g id="Group_999" data-name="Group 999" transform="translate(-19)">
                <g id="Group_998" data-name="Group 998" transform="translate(240.474 415.793)">
                    <path id="Rectangle_364" data-name="Rectangle 364" d="M-.737-2.5H18.118A1.765,1.765,0,0,1,19.881-.737V29.431a1.765,1.765,0,0,1-1.763,1.763H-.737A1.765,1.765,0,0,1-2.5,29.431V-.737A1.765,1.765,0,0,1-.737-2.5ZM18.118,29.807a.377.377,0,0,0,.376-.376V-.737a.377.377,0,0,0-.376-.376H-.737a.377.377,0,0,0-.376.376V29.431a.377.377,0,0,0,.376.376Z" transform="translate(2.5 2.5)" fill="#2e303b"/>
                    <path id="Rectangle_366" data-name="Rectangle 366" d="M-1.807-2.5H14.2a.693.693,0,0,1,.693.693V25.8a.693.693,0,0,1-.693.693H-1.807A.693.693,0,0,1-2.5,25.8V-1.807A.693.693,0,0,1-1.807-2.5ZM13.508-1.113H-1.113V25.109H13.508Z" transform="translate(4.993 4.989)" fill="#2e303b"/>
                </g>
                <path id="Union_1" data-name="Union 1" d="M1.466,6.787H0V0H8.849V6.787h-3.8C5.016,6.814,2.323,9.3,1.466,9.434a.376.376,0,0,1-.058,0C.648,9.439,1.457,6.815,1.466,6.787Z" transform="translate(247.24 427.92)" fill="rgba(0,0,0,0)"/>
                <path id="Union_1_-_Outline" data-name="Union 1 - Outline" d="M322.6,1014.326h0a.948.948,0,0,1-.77-.389,2.53,2.53,0,0,1-.127-1.993c.018-.092.037-.183.057-.271h-.569a.693.693,0,0,1-.693-.693v-6.787a.693.693,0,0,1,.693-.693h8.849a.693.693,0,0,1,.693.693v6.787a.693.693,0,0,1-.693.693h-3.535c-.265.239-.76.678-1.308,1.128-1.516,1.241-2.1,1.46-2.434,1.512A1.071,1.071,0,0,1,322.6,1014.326Zm-.715-4.039h.773a.693.693,0,0,1,.662.9s-.13.421-.231.9a6.279,6.279,0,0,0-.1.631,13.733,13.733,0,0,0,1.365-1.017c.753-.619,1.409-1.225,1.416-1.231a.694.694,0,0,1,.471-.184h3.11v-5.4h-7.462Z" transform="translate(-73.954 -576.273)" fill="#2e303b"/>
                </g>
                <path id="Union_2" data-name="Union 2" d="M3610.808,3341.982a.664.664,0,0,1,.092-.943l1.547-1.257h-7.239a.682.682,0,0,1,0-1.339h7.3l-1.741-1.227a.663.663,0,0,1-.158-.935.684.684,0,0,1,.948-.155l3.311,2.334a.666.666,0,0,1,.037,1.063l-3.141,2.549a.684.684,0,0,1-.956-.091Z" transform="translate(-3359.164 -2906.473)" fill="#2e303b"/>
            </g>
        </svg>
    )
}