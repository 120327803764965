'use client'

import Modal from 'components/feedback/Modal'
import Style from './RejoinerEmailToaster.module.scss'
import { IconClose } from 'components/icons/IconClose'
import { IconLogoOcean } from 'components/icons/IconLogoOcean'
import SubmissionForm from './SubmissionForm'
import useRejoiner from 'thirdparty/Rejoiner/useRejoiner'
import Confirmation from './Confirmation'
import { useCallback, useState, useEffect } from 'react'
import Picture from 'components/display/Picture'
import { isMobile } from 'react-device-detect'
import { sendEmailToList } from 'thirdparty/Rejoiner/Rejoiner.service'

function RejoinerEmailToaster(){
    const [ success, setSuccess ] = useState(false);
    const [isAlreadySubscribed, setIsAlreadySubscribed] = useState(false);
    const [ formStep, setFormStep ] = useState(1);
    const [userEmail, setUserEmail] = useState("");
    const [ err, setError ] = useState(false);
    const rejoiner = useRejoiner();

    const closeModal = useCallback(() => {
        if (!isMobile && userEmail && formStep === 2 && !success) {
            sendEmailToList(userEmail);
        }
        rejoiner.closeModal();
    }, [isMobile, userEmail, rejoiner, formStep, success]);
    

    return(
        <>
            <Modal
                shouldOpen={rejoiner.openModal}
                onClose={closeModal}
                className={Style.modal}
                size='md'
                showHeader={false}
                willClose={closeModal}
            >
                <button type="button" className={Style.closeBtn} onClick={closeModal}>
                    <IconClose width={30} height={30} />
                </button>

                <div className={Style.block}>
                    <div className={Style.content}>
                        <IconLogoOcean className={Style.logo} />

                        {success || isAlreadySubscribed ? (
                            <Confirmation 
                                rejoiner={rejoiner} 
                                isAlreadySubscribed={isAlreadySubscribed} 
                                closeModal={closeModal} 
                            />
                        ) : (
                            <SubmissionForm 
                                onSuccess={() => setSuccess(true)}
                                formStep={formStep}
                                setFormStep={setFormStep}
                                closeModal={closeModal}
                                isMobile={isMobile}
                                userEmail={userEmail}
                                setUserEmail={setUserEmail}
                                isAlreadySubscribed={isAlreadySubscribed}
                                setIsAlreadySubscribed={setIsAlreadySubscribed}
                                setError={setError}
                            />
                        )}
                    </div>

                    {!isMobile && (
                        <Picture
                            key={`image-${success}-${formStep}`}
                            desktop={
                                success
                                    ? rejoiner?.images?.desktop.confirmation
                                    : formStep === 1 
                                    ? rejoiner?.images?.desktop.rule ?? rejoiner?.images?.desktop.fallback 
                                    : rejoiner?.images?.desktop.phone
                            }
                            alt="Sign up now"
                            className={Style.image}
                            width={387}
                            height={580}
                            priority
                        />
                    )}

                </div>
            </Modal>
        </>
    )
}

export default RejoinerEmailToaster
