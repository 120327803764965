// Main Navigation Hard Coded
export const mainNavItems = [
    {
        name: "Outdoor Furniture",
        seoName: "All Outdoor Furniture",
        id: "flyoutOutdoorFurniture",
        type: "outdoor-furniture",
        href: "/outdoor-furniture",
        height: "short"
    },
    {
        name: "Umbrellas",
        seoName: "Patio Umbrellas and Accessories",
        id: "flyoutUmbrellas",
        type: "patio-umbrellas-accessories",
        href: "/patio-umbrellas-accessories",
        height: "short"
    },
    {
        name: "Heating",
        seoName: "All Outdoor Heating",
        id: "flyoutHeating",
        type: "outdoor-heating",
        href: "/outdoor-heating",
        height: "wee"
    },
    {
        name: "Outdoor Decor",
        seoName: "Outdoor Decor",
        id: "flyoutOutdoorDecor",
        type: "outdoor-decor",
        href: "/outdoor-decor",
        height: "wee"
    },
    {
        name: "Planters",
        seoName: "All Planters",
        id: "flyoutPlanters",
        type: "planters",
        href: "/planters",
        height: "wee"
    },
    {
        name: "Grills & Kitchens",
        seoName: "Grills and Outdoor Kitchens",
        id: "flyoutGrillsKitchens",
        type: "grills-outdoor-kitchens",
        href: "/grills-outdoor-kitchens",
        height: "short"
    },
    {
        name: "Accessories",
        seoName: "All Patio Accessories",
        id: "flyoutAccessories",
        type: "accessories",
        href: "/accessories",
        height: "wee"
    },
    {
        name: "Brands",
        seoName: "All Brands",
        id: "flyoutBrands",
        type: "shop-all-brands",
        href: "/shop-all-brands",
        height: "default"
    }
];
