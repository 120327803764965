"use client"

import PropTypes from 'prop-types';
import { Suspense, lazy, useContext, useEffect, useState } from "react";
import clsx from "clsx";
import useMeasure from 'react-use/lib/useMeasure';
import { useIntersect } from "custom_hooks/useIntersect";
import HeaderLogo from "components/applications/header_footer/Header/components/HeaderLogo";
import MainNav from "components/applications/header_footer/Header/components/MainNav";
import MyCartBtn from "components/applications/header_footer/Header/components/MyCartBtn";
import MyAccountBtn from "components/applications/header_footer/Header/components/MyAccountBtn";
import AuxiliaryLinks from './components/AuxiliaryLinks';
import HeaderSearch from 'components/applications/search/HeaderSearch';
import Chat from 'components/help/Chat';
import { GlobalContext } from 'contexts/GlobalContext';
import useImmobility from './hooks/useImmobility';
import { HeaderDropdownContext } from 'components/applications/header_footer/Header/contexts/HeaderContext';
import { usePathname, useSearchParams } from 'next/navigation';
import Style from 'components/applications/header_footer/Header/styles/Header.module.scss';
import BannerLoader from 'components/ads/BannerLoader';
import MenuBtn from './components/MenuBtn';


const DesktopMobileFlyout = lazy(() => import('./components/Flyout/DesktopMobileFlyout'))
const PencilBanner = lazy(() => import('components/ads/PencilBanner'))
const TradePencilBanner = lazy(() => import('components/ads/TradePencilBanner'))
// const CountdownPencilBanner = dynamic(() => import('components/ads/CountdownPencilBanner'))


function DesktopHeader({ 
    MobileStyle,
    isLandscape, 
    isPortrait, 
    headerTop, 
    showUnderlay, 
    updateOverlay, 
    flyoutShown, 
    MainNavStyle, 
    AuxStyle,
    LogoStyle,
    MegaFlyoutStyle,
    HeaderSearchStyle,
    ChatStyle,
    MyCartStyle,
    PencilBannerStyle,
    UtilityStyle,
    MenuBtnStyle,
    isMobile
}){

    const [ global, _ ] = useContext(GlobalContext);
    const [ dropDownShown, setDropDownShown ] = useState(false);
    const pathName = usePathname()
    const searchParams = useSearchParams()
    const flyoutQuery = searchParams.get("flyout")
    const [ isOpen, openMobileFlyout ] = useState(false);


    // isImmobile as in the header is not scrolling and is in a non-moving position 
    const [ isImmobile, setImmobility ] = useState(true);

    const [ headerRef, entry ] = useIntersect({
        threshold: 1.0,
        rootMargin: "205px"
    });

    const [ headerWrapper, { x, y, width, height, top, right, bottom, left }] = useMeasure();



    const canImmobilize = useImmobility({ entry, pathName })

    useEffect(() => {
        setImmobility(canImmobilize);

        return () => {
            setImmobility(false);
        }

    }, [ canImmobilize ])




    return(   
        <>
            {isOpen && (
                <DesktopMobileFlyout 
                    Style={MobileStyle}
                    {...{ PencilBannerStyle, MainNavStyle }}
                    customer_group_id={global.user.customer_group_id}
                    toggleFlyout={() => openMobileFlyout(false)}
                />
            )}

            <div className={Style.cntr} ref={headerRef}>
                <div className={Style.wrapper} ref={headerWrapper}>
                    <div className={Style.auxCntr}>
                        <AuxiliaryLinks Style={AuxStyle} />
                    </div>

                    <div className={Style.bodyWrapper}>
                        <div className={clsx(Style.bodyCntr, !isImmobile && Style.bodyCntr__fixed)}>
                            <div className={Style.body}>  
                                <div className={clsx(Style.logoBlock, !isImmobile && Style.bar__logoChat)}>
                                    <MenuBtn 
                                        Style={MenuBtnStyle}
                                        toggleFlyout={() => openMobileFlyout(true)}
                                        {...{ isImmobile }} 
                                    />

                                    <HeaderLogo 
                                        headerIsFixed={isImmobile} 
                                        Style={LogoStyle}
                                        isDesktop
                                        {...{ user: {...global.user}, isPortrait, isLandscape }}
                                    />
                                    
                                    {/* <Chat 
                                        type="full" 
                                        Style={ChatStyle}
                                    /> */}
                                </div>


                                <HeaderSearch 
                                    headerIsFixed={isImmobile} 
                                    isLandscape={isLandscape} 
                                    Style={HeaderSearchStyle}
                                />
                                

                                <div className={clsx(Style.bar, Style.utils)}>
                                    <HeaderDropdownContext.Provider value={{ dropDownShown, setDropDownShown }}>
                                        <MyAccountBtn 
                                            headerIsFixed={isImmobile} 
                                            hideFlyout={showUnderlay} 
                                            {...{ user: {...global.user}, isPortrait, isLandscape }}
                                            isDesktop
                                            Style={UtilityStyle}
                                        />

                                        <MyCartBtn 
                                            {...{ user: {...global.user} }} 
                                            Style={MyCartStyle}
                                        />
                                    </HeaderDropdownContext.Provider>
                                </div>
                            </div>

                            <MainNav 
                                isDesktop
                                isTrade={global.user.customer_group_id === 1}
                                isMega={flyoutQuery === "mega"}
                                isIntersecting={entry.isIntersecting}
                                Style={MainNavStyle}
                                MegaFlyoutStyle={MegaFlyoutStyle}
                            />
                            
                        </div>
                    </div>
                </div>
            </div>

            <Suspense fallback={<BannerLoader />}>
                {global.user.customer_group_id !== 1 ? (
                    <PencilBanner Style={PencilBannerStyle} />
                    // <CountdownPencilBanner />
                ):(
                    <TradePencilBanner />
                )}
            </Suspense>
        </>
    )
}


DesktopHeader.propTypes = {
    isLandscape: PropTypes.bool,
    isPortrait: PropTypes.bool
};



export default DesktopHeader;