"use client"

import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Style from './Switch.module.scss';

const Switch = React.forwardRef(({
    className,
    disabled,
    name,
    value,
    required,
    label,
    labelClass,
    onChange,
    onColor,
    isToggled,
    id

}, ref) => {

    const [isOn, setOn] = useState(isToggled)

    const toggleSwitch = (e) => {
        setOn(!isOn);
        onChange(e);
    }

    useEffect(() => {
        setOn(isToggled);

    }, [isToggled])





    return (
        <label 
            className={clsx(Style.block, className, disabled && Style.disabled)} 
            role="switch" 
            aria-checked={isOn}
        >
            <div className={Style.controlGroup} aria-disabled={disabled !== undefined}>            
                <input
                    className={clsx(Style.control)}
                    type="checkbox"
                    onChange={toggleSwitch}
                    {...{ id, name, value, required, disabled, ref }}
                    checked={isOn}
                />
            </div>

            {label && (
                <div className={clsx(Style.label, labelClass && labelClass)}>
                    {label}
                </div>
            )}
        </label>
    )
})

Switch.displayName = "Switch";

Switch.propTypes = {
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string || PropTypes.bool,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    required: PropTypes.bool,
    className: PropTypes.string,
    labelClass: PropTypes.string
};


export default Switch;