



// My Account Dropdown
export const MyAccountFlyout = [
    {
        name: "Orders",
        title: "View order status",
        url: "/account/order-status"
    },
    {
        name: "Profile",
        title: "Update your account",
        url: "/account/profile"
    },
    {
        name: "Address Book",
        title: "For a faster checkout",
        url: "/account/address-book"
    },
    {
        name: "Recently Viewed",
        title: "Your product viewing history",
        url: "/account/recently-viewed"
    }
];