"use client"

import Style from './Chat.module.scss';
import headerUtil from 'components/applications/header_footer/Header/styles/HeaderUtility.module.scss';
import PropTypes from 'prop-types';
import clsx from "clsx";
import { triggerChat } from 'thirdparty/Zendesk/Zendesk.service';
import { isTablet } from 'react-device-detect';
import { IconChatOnline } from 'components/icons/IconChatOnline';
import { IconChatOffline } from 'components/icons/IconChatOffline';
import { IconChatBubble } from 'components/icons/IconChatBubble';
import { IconDot } from 'components/icons/IconDot';
import { useContext, useEffect } from 'react';
import { useState } from 'react';
import { GlobalContext } from 'contexts/GlobalContext';
import { usePathname, useSearchParams } from 'next/navigation';



function Chat({ type, headerIsFixed, isLandscape, className }){
    const [global, _] = useContext(GlobalContext)
    const [ status, setStatus ] = useState({
        online: false,
        offline: true
    })

    const pathName = usePathname()
    const searchParams = useSearchParams()


    useEffect(() => {
        try{
            if( window.zE ){
                // function changeChatImg(status) {
                //     console.log(status)
                //     switch(status){
                //         case 'online': 
                //             setStatus({
                //                 online: true,
                //                 offline: false
                //             })
                //             break;

                //         case 'away': break;
                //         case 'offline':
                //             setStatus({
                //                 online: false,
                //                 offline: true
                //             })
                //             break;
                //     }			
                // }

                // window.zE('messenger:on', 'chat', changeChatImg);
            }

        }catch(err){
            console.log(err)
        }
       
    }, [ 
        global?.thirdParty?.zendesk, 
        pathName,
        searchParams
    ])

    
    return(
        <>
            {type === "full" && (
                <div className={clsx(Style.block, className)}>
                    <button type="button" className={clsx(Style.btn, Style.fullSize)} onClick={triggerChat} title="Chat with us today!">
                        <IconChatOffline isDesktop width="168" height="19" className={clsx(Style.icon, status.offline && Style.iconShow)} />
                        <IconChatOnline isDesktop width="168" height="19" className={clsx(Style.icon, status.online && Style.iconShow)} />
                    </button>
                </div>
            )}

                
            {type === "icon" && (headerIsFixed || (isTablet && isLandscape)) && (
                <button className={clsx(headerUtil.button, headerUtil.chat, className, Style.iconSize)} type="button" onClick={triggerChat} title="Chat with us today!">
                    <IconChatOffline isMobile className={clsx(Style.icon, status.offline && Style.iconShow)} width="23" height="20" />
                    <IconChatOnline isMobile className={clsx(Style.icon, status.online && Style.iconShow)} width="23" height="20" />
                </button>
            )}


            {type === "footer" && (
                <button type="button" onClick={triggerChat} title="Chat" className={className} >
                    <IconChatBubble width="30" height="30" className={clsx(Style.bubbleIcon, status.online && Style.statusOnline)}/>

                    <span className={clsx(Style.callText, Style.callTextChat, status.online && Style.statusOnlineText)}>
                        {status.online ? "Launch Live Chat" : "Live Chat"}
                        <small className={Style.statusDotCntr}>
                            <IconDot width="10" height="10" className={clsx(Style.statusDot, status.online && Style.statusOnline)} />

                            <span className={clsx(Style.statusDotText, status.online && Style.statusOnlineText)}>
                                {status.online ? "Online" : "Offline"}
                            </span>
                        </small>
                    </span>
                </button>
            )}
        </>
    )
}


Chat.propTypes = {
    headerIsFixed: PropTypes.bool,
    type: PropTypes.oneOf(["icon", "full", "footer"]),
    isLandscape: PropTypes.bool
};



export default Chat;