import { useEffect } from 'react'
import Style from './RejoinerEmailToaster.module.scss'
import Button from 'components/forms/Button'
import utils from 'styles/globals/utils.module.scss';
import clsx from 'clsx';


function Confirmation({ rejoiner, isAlreadySubscribed, closeModal }){

    useEffect(() => {
        setTimeout(() => {
            closeModal()
        }, 3000)
    }, [])


    return(
        <>

            {isAlreadySubscribed ? (
                <>
                    <p className={clsx(Style.callOut, utils.mt_2, utils.mb_0)}>
                        HI AGAIN!
                    </p>

                    <p className={Style.heading}>
                        LOOKS LIKE YOU'RE <br />
                        ALREADY REGISTERED
                    </p>
                    <p className={clsx(Style.text, utils.mb_2)}>
                        You'll continue receiving messages<br />
                        about promotions, sales, and new releases.
                    </p>

                    <Button 
                        type="button" 
                        color="ocean"
                        size="full"
                        className={Style.submitBtn}
                        onClick={closeModal}
                    >
                        Start Shopping
                    </Button>
                </>
            ) : (
                <>
                    <p className={clsx(Style.callOut, utils.mt_2, utils.mb_0)}>
                        THANKS FOR<br />SIGNING UP!
                    </p>

                    <p className={clsx(Style.text, utils.mb_2)}>
                        Check your texts and reply "Y"<br />to confirm your subscription.
                    </p>

                    <Button 
                        type="button" 
                        color="ocean"
                        size="full"
                        className={Style.submitBtn}
                        onClick={closeModal}
                    >
                        Start Shopping
                    </Button>
                </>
            )}
        </>
    );
}

export default Confirmation