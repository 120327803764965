

export function GetFlyout(slug) {
  return` 
    query GetFlyout {
      entries(section: "flyouts", slug: "${slug}") {
        ... on flyouts_default_Entry {

          flyoutBuilder {
            ... on column_Entry {
              headingTitle
              headingHref
              headingImage {
                url
                filename
                width
                height
              }
              typeHandle
              id
              kids {
                ... on kidsBlock_Entry {
                  id
                  kidHref
                  kidTitle
                  kidDisplay
                  kidImg {
                    url
                    filename
                    width
                    height
                  }
                }
              }
            }

            ... on featured_Entry {
              typeHandle
              features {
                ... on featuresBlock_Entry {
                  featureTitle
                  featureHref
                  id
                  featureImage {
                    url
                    filename
                    width
                    height
                  }
                }
              }
            }

            ... on blogs_Entry {
              typeHandle
              blogPosts {
                ... on blogPostsBlock_Entry {
                  id
                  blogTitle
                  blogHref
                }
              }
              blogImage {
                url
                filename
                width
                height
              }
            }
          }
        }
      }
    }
  `
}