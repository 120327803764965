import Link from 'next/link';

export default function AuxiliaryLinks({ Style }){
	// const auxNav = GetHeaderAuxNav()

    const auxNav = [
        {
            title: "Trade & Hospitality",
            url:  "/c/trade/"
        },
        {
            title: "Financing",
            url:  "/c/affirm/"
        },
        {
            title: "Gift Cards",
            url: "/p/gift-card/"
        },
        {
            title: "Track Order",
            url:  "/account/order-status/"
        },
        {
            title: "Blog",
            url:  "/blog/"
        },
        {
            title: "Help",
            url:  "/help/"
        },
    ]

    const auxStyle = {
        justifyContent: "flex-end",
        listStyle: "none",
        margin: "0 0 5px 0",
        padding: 0,
        flex: "1 0 auto",
        width: "100%",
        height: "20px",
        display: "flex",
    }
        
    return (
        <ul className={Style.block} style={auxStyle}>
            {auxNav.map(items => {
                return (
                    <li className={Style.auxItem} key={items.title}>
                        <Link href={items?.url} title={`Go to ${items.title} page`} className={Style.auxLink}>
                            {items.title}
                        </Link>
                    </li>
                )
            })}
        </ul>
    );
}