"use client"

import Style from './WidgetDrawer.module.scss'
import Backdrop from "../Backdrop";
import { useContext, useEffect } from 'react';
import clsx from 'clsx';
import useWrapperBlur from 'custom_hooks/useWrapperBlur';
import { GlobalContext } from 'contexts/GlobalContext';
import { usePathname } from 'next/navigation';


function WidgetDrawer(){
    const [ global, dispatch ] = useContext(GlobalContext)
    const blurWrapper = useWrapperBlur()
    const pathName = usePathname()


    useEffect(() => {
        setTimeout(() => {
            dispatch({
                type: "closeWidget",
                data: null
            })

        }, 100)

    }, [ pathName ])

    
    useEffect(() => {
        blurWrapper[global?.widget?.isOpen ? "blur" : "unBlur"].call(null)

    }, [ global?.widget?.isOpen, blurWrapper ])


    return(
        <>
            <dialog className={clsx(Style.block, global?.widget?.isOpen && Style.open)} open>
                {global?.widget?.page?.component ?? global?.widget?.component}
            </dialog>

            <Backdrop 
                show={global?.widget?.isOpen} 
                onClick={() => {
                    dispatch({
                        type: "closeWidget",
                        data: null
                    })
                }}
            />
        </>
    )
}

export default WidgetDrawer;