import Switch from 'components/forms/Switch'
import Loader from 'components/feedback/Loader'
import Button from 'components/forms/Button'
import Input from 'components/forms/Input'
import { useForm, Controller } from 'react-hook-form'
import {  useState, useEffect } from 'react'
import Style from './RejoinerEmailToaster.module.scss'
import clsx from 'clsx'
import { isMobile } from 'react-device-detect'
import { IconSmsIcon } from 'components/icons/IconSmsIcon'
import { postForm, submitPhoneForm } from 'thirdparty/Rejoiner/Rejoiner.service'


function SubmissionForm({ onSuccess, formStep, setFormStep, closeModal, setUserEmail, userEmail, setIsAlreadySubscribed }) {
    const [customerType, setCustomerType] = useState("retail");
    const [ loading, setLoading ] = useState(false);
    const [ success, setSuccess ] = useState(false);
    const [ err, setError ] = useState(false);

    const { handleSubmit, control, reset,unregister, setValue } = useForm({
        defaultValues: {
            email: "",
            phone: "",
        },
    });

    useEffect(() => {
        if (formStep === 2) {
            unregister("email");
            reset({ phone: " "});
            setValue("phone", "");
        }
    }, [formStep, reset, unregister, setValue]);

    

    

    return (
        <>
            {formStep === 1 ? (
                <>
                    <p className={Style.heading}>DO YOU WANT TO WIN A</p>
                    <p className={Style.callOut}>
                        $1,000 Gift Card?
                    </p>
                    <p className={Style.text}>
                        Join our email list for your chance to win,<br />
                        and be the first to hear about<br />
                        new product releases and exclusive savings.
                    </p>
                </>
            ) : (
                <>
                    <p className={Style.heading}>YOU'VE SUCCESSFULLY ENTERED!</p>
                    <p className={Style.callOutPhone}>
                        WANT ANOTHER<br />
                        CHANCE TO WIN?
                    </p>
                    <p className={clsx(Style.text, Style.desktopOnly)}>
                        Get exclusive offers when you<br />
                        sign up for texts.
                    </p>
                </>
            )}

            <form onSubmit={handleSubmit(formStep === 1 
                ? (data) => postForm({ formData: data, setLoading, setSuccess, setFormStep, setIsAlreadySubscribed, setUserEmail, isMobile }) 
                : (data) => submitPhoneForm({ formData: data, userEmail, setLoading, setSuccess, onSuccess, setIsAlreadySubscribed, setError })
            )} className={Style.form}>
                {formStep === 2 ? (
                    <>
                        {/* PHONE FORM */}
                        <div className={Style.control}>
                            {!isMobile && formStep === 2 && (
                                <Controller
                                    name="phone"
                                    control={control}
                                    key={formStep}
                                    rules={{
                                        required: "Phone number is required",
                                        pattern: {
                                            value: /^(\+1)?([2-9][0-9]{2}[2-9][0-9]{6})$/,
                                            message: "Enter a valid phone number",
                                        },
                                    }}
                                    
                                    render={({ field }) => (
                                        <Input
                                            {...field}
                                            type="tel"
                                            required
                                            autoFocus
                                            label="Phone Number*"
                                            placeholder="(555) 867-5309"
                                            className={Style.customInput}
                                            onInput={(e) => {
                                                if (e?.target?.value) {
                                                    e.target.value = e.target.value.replace(/[^0-9\-\(\)]/g, "");
                                                }
                                            }}
                                        />
                                        
                                    )}
                                />
                            )}
                            
                            {/* Mobile: Show SMS Button */}
                            {isMobile && formStep === 2 && (
                                <a
                                    href="sms:+18777558325?body=YES, I would like to subscribe to alerts from AuthenTEAK about promotions, sales, and new releases."
                                    className={Style.smsButton}
                                >
                                    <span className={Style.smsText}>
                                        <strong>GET EXCLUSIVE OFFERS</strong>
                                        <br />
                                        When you sign up for texts.
                                    </span>
                                    <IconSmsIcon />
                                </a>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                      {/* EMAIL FORM */}
                      <div className={Style.control}>
                            <Controller
                                name="email"
                                control={control}
                                rules={{
                                    required: "Email is required",
                                    pattern: {
                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                        message: "Invalid email format",
                                    },
                                }}
                                render={({ field }) => (
                                    <Input
                                        {...field}
                                        type="email"
                                        required
                                        autoFocus
                                        label="Email Address*"
                                        placeholder="you@example.com"
                                        className={Style.customInput}
                                    />
                                )}
                            />
                        </div>

                        <div className={Style.control}>
                            <Controller
                                name="customer_type"
                                control={control}
                                render={({ field }) => (
                                    <Switch 
                                        {...field}
                                        label="I am a trade professional"
                                        isToggled={customerType === "trade"}
                                        onChange={() => setCustomerType(prev => prev === "retail" ? "trade" : "retail")}
                                        className={Style.customSwitch}
                                        labelClass={Style.customLabel}
                                    />
                                )}
                            />
                        </div>
                    </>
                )}
                {(formStep === 1 || (formStep === 2 && !isMobile)) && (
                    <div className={Style.controlButton}>
                        <Button
                            type="submit"
                            color="ocean"
                            size="sm"
                            className={Style.submitBtn}
                        >
                             {loading ? <Loader isLoading={loading} isSuccessful={success} hasError={err} /> : "Enter to win"}
                        </Button>
                    </div>
                )}
                <button
                    type="button"
                    className={Style.noThanks}
                    onClick={closeModal}
                >
                    {formStep === 1 ? "No thanks" : "No thanks, I don’t want texts"}
                </button>
                {/* Terms & Conditions (Only in Phone Form) */}
                {formStep === 2 && (
                    <p className={Style.terms}>
                        By submitting this form, you agree to receive recurring automated promotional and personalized marketing text messages (e.g. cart reminders) from AuthenTEAK at the cell number used when signing up. Consent is not a condition of any purchase. Reply HELP for help and STOP to cancel. Msg frequency varies. Msg and data rates may apply. View Terms & Privacy Policy.
                    </p>
                )}
            </form>
        </>
    );
}

export default SubmissionForm;