import { parseCookies } from 'nookies'
import { useEffect, useMemo, useRef, useState } from 'react'
import { usePathname } from 'next/navigation'
import { LocalStorage, storageKeys } from 'services/LocalStorage.service'
import { getCMSRejoinerRules } from './Rejoiner.service'
import { setRejoinerDismissedCookie, validatePaths } from './Rejoiner.helpers'
import { cloudinaryCmsImageLoader } from 'thirdparty/Cloudinary/cloudinaryLoader'



export default function useRejoiner(){
    const rejoinerModalTimeout = useRef(null)
    const pathName = usePathname()
    const [ rules, setRules ] = useState(null)
    const [ openModal, setOpenModal ] = useState(false)

    useEffect(() => {
        // session storage will cause a fetch fresh for every user session.  
        // TODO: Can (should?) convert to local storage and add some sort of time to live value
        const storedRules = LocalStorage.getStorage(storageKeys.rejoiner.rules)

        if( storedRules ){
            setRules(storedRules)
        }else{
            getCMSRejoinerRules().then(response => {
                LocalStorage.setStorage(storageKeys.rejoiner.rules, response)
                setRules(response)
            })
        }
    }, [])


    const images = useMemo(() => {
        return {
            desktop: {
                rule: rules?.image?.[0]?.filename ? cloudinaryCmsImageLoader({ 
                    src: rules.image?.[0]?.filename ?? "",
                    width: 400,
                    quality: 80 
                }) : null,
                fallback: "https://res.cloudinary.com/authenteak/image/upload/v1738345483/e-mail-modal-desktop-67603a2963522_lvlqnw.webp",
    
                // Phone Form Image
                phone: rules?.phoneImage?.[0]?.filename ? cloudinaryCmsImageLoader({ 
                    src: rules.phoneImage?.[0]?.filename ?? "",
                    width: 400,
                    quality: 80 
                }) : "https://res.cloudinary.com/authenteak/image/upload/v1738345483/sms-modal-desktop-67603a2a343fe_l14qar.webp",
    
                //  Confirmation Image
                confirmation: rules?.confirmationImage?.[0]?.filename ? cloudinaryCmsImageLoader({ 
                    src: rules.confirmationImage?.[0]?.filename ?? "",
                    width: 400,
                    quality: 80 
                }) : "https://res.cloudinary.com/authenteak/image/upload/v1738854537/sms-modal-desktop-67a39fbc6d261_s3urjy.webp"
            },
        };
    }, [ 
        rules?.image?.[0]?.url,
        rules?.phoneImage?.[0]?.url,
        rules?.confirmationImage?.[0]?.url,
    ]);
    
    


    
    useEffect(() => {
        // check to see if the current path matches those set in the rules
        const inValidPath = validatePaths(pathName, rules?.doNotShow)

        // check if we have cookies set to not show this modal
        const cookieStore = parseCookies()

        const hasDismissedCookie = cookieStore?.TEAK_rejoinerEmailToaster
        const hasRejoinerProfile = cookieStore?.TEAK_rejoinerCustomerProfile

        if (hasDismissedCookie || hasRejoinerProfile || inValidPath) {
            //Clear timeout if we're on invalid path
            if (inValidPath && rejoinerModalTimeout.current) {
                clearTimeout(rejoinerModalTimeout.current)
            }
            return; //exit
        }

        const delay = (rules?.delay ?? 10) * 1000;
        rejoinerModalTimeout.current =  setTimeout(() => {
            setOpenModal(true)
        }, delay);

        return() => {
            clearTimeout(rejoinerModalTimeout.current);
        };

    }, [ pathName, rules ])




    function dismissRejoinerModal(){
        setOpenModal(false)
        setRejoinerDismissedCookie(rules)

        clearTimeout(rejoinerModalTimeout.current)
    }



    return {
        rules,
        images,
        openModal,
        closeModal: dismissRejoinerModal,
    };
}